<template>
  <q-tabs
    :model-value="modelValue"
    @update:model-value="$emit('update:modelValue', $event)"
    align="left"
    indicator-color="primary"
    switch-indicator
    no-caps
    class="shadow-default"
    style="max-width: min-content;"
  >
    <q-tab
      v-for="tab in tabs"
      :key="tab.name + '-tab'"
      :name="tab.name"
      style="min-width: 250px"
      :disable="disabled"
    >
      <h6 class="flex items-center">
        <slot :name="tab.name + '-label-prepend'" />
        <slot :name="tab.name + '-label'" />
        <q-icon
          v-if="'labelIcon' in tab"
          :name="tab.labelIcon"
          class="q-mr-sm"
          size="1.3em"
        />
        <span v-if="'label' in tab">{{ tab.label }}</span>
        <slot :name="tab.name + '-label-append'" />
      </h6>
    </q-tab>
  </q-tabs>
  <div class="card-content">
    <slot name="card-content" />
  </div>
</template>

<script>
export default {
  name: "TabsContent",
  props: {
    tabs: {
      type: Array,
      default: () => []
    },
    modelValue: {
      type: String
    },
    cardClasses: {
      type: String,
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ["update:modelValue"],
  data() {
    return {};
  },
  methods: {}
};
</script>

<style scoped lang="scss">
.card-content {
  *:first-child {
    border-top-left-radius: 0;
  }
}
</style>
