<template>
  <div>
    <PageTitlebar
      title="Accounts"
      :title-icon="getAssetIcon('accounts')"
    >
      <template v-slot:sub-title>
        Manage your advertiser accounts and inventory sources. <a href="javascript:void(0)" @click="promptAccountsInfoSidePanel()">Learn More</a>
      </template>
    </PageTitlebar>

    <template
      v-if="
        $store.getters['business/advertiserInitialFlowRestricted'] ||
          (accounts.length === 0 && !loading)
      "
    >
      <div class="column justify-center items-center q-pt-xl">
        <q-icon
          name="sym_r_add_to_queue"
          color="subtle"
          size="110px"
          class="q-mb-lg"
        />
        <template v-if="$store.state.user.record.type === 'admin'">
          <h5 class="q-mb-xs">
            Get started by adding a new Advertiser Account to your business.
          </h5>
          <p class="text-center text-font-secondary">
            An Advertiser Account is used to connect your inventory source(s) to
            the network and <br />
            apply settings such as a bid and budget to the {{ $store.state.productTypeConfigs.type }}s that you
            offer.
          </p>
          <q-btn
            label="Add New Account"
            :to="{ name: 'AdvertiserAccountCreator' }"
            color="primary"
            class="q-mt-lg"
          />
        </template>
        <template v-else>
          <h5 class="q-mb-xs">
            This business has no Advertiser Accounts.
          </h5>
          <p class="text-center text-font-secondary">
            An advertiser account is used to connect your inventory source(s) to
            the network and <br />
            apply settings such as a bid and budget to the {{ $store.state.productTypeConfigs.type }}s that you
            offer. <br /><br />
            An admin of your business must create a new advertiser account.
          </p>
        </template>
      </div>
    </template>
    <template v-else>
      <TabsContent
        v-model="selectedView"
        :disabled="accounts.length === 0"
        :tabs="[
          {
            name: 'accounts',
            label: 'Accounts',
            labelIcon: getAssetIcon('accounts')
          },
          {
            name: 'sources',
            label: 'Sources',
            labelIcon: getAssetIcon('sources')
          }
        ]"
      >
        <template
          v-if="manageSourcesSelectedAccountIds.length > 0"
          v-slot:accounts-label-append
        >
          <q-badge
            color="primary"
            :label="manageSourcesSelectedAccountIds.length"
            class="q-ml-sm text-weight-medium"
          />
        </template>

        <template v-slot:card-content>
          <DataTable
            v-if="selectedView == 'accounts'"
            table-key="accountsOverview"
            row-key="id"
            :loading="loading"
            spinner-loader
            :columns="accountsColumns"
            :rows="accounts"
            :pagination="{
              sortBy: 'account',
              descending: false,
              page: 1,
              rowsPerPage: 10,
            }"
            sticky-column-table
            :downloadable="true"
            :refreshable="true"
            :customizable="false"
            :filterable="true"
            :searchable="true"
            :disable-state="true"
            :custom-styles="{ 'border-top-left-radius': 0 }"
            :time-last-updated="accountsTimeLastUpdated"
            @refresh-rows="
              () => {
                populateAdvertiserAccounts();
              }
            "
            local-rows-listener
            @local-rows-changed="localRows => updateAccountsTotals(localRows)"
            custom-totals-row
          >
            <template v-slot:title>
              <q-btn
                v-if="$store.state.user.record.type === 'admin'"
                round
                dense
                icon="sym_r_add"
                :to="{ name: 'AdvertiserAccountCreator' }"
                color="primary"
              >
                <q-tooltip anchor="center right" self="center left">
                  Add New Account
                </q-tooltip>
              </q-btn>
            </template>

            <template v-slot:account="row">
              <div class="flex items-center no-wrap justify-between">
                <div class="flex items-center no-wrap">
                  <q-toggle
                      dense
                      color="primary"
                      class="q-mr-sm"
                      :model-value="row.active == 1 && row.paused == 0"
                      @update:model-value="
                    value => {
                      !value
                        ? promptStopAccount(row).then(() =>
                            populateAdvertiserAccounts()
                          )
                        : promptResumeAccount(row).then(() =>
                            this.populateAdvertiserAccounts()
                          );
                    }
                  "
                  >
                    <q-tooltip anchor="center right" self="center left">
                      Toggle On/Off
                    </q-tooltip>
                  </q-toggle>
                  <div>
                    <span>{{ row.name }}</span>
                    <div class="flex items-center">
                      <Label :text="row.id" />
                      <q-badge
                          v-if="row.business_id != $store.state.business.record.id"
                          color="gray-light"
                          text-color="gray-medium-dark"
                          :label="'Shared by ' + row.business_name"
                          class="q-ml-sm"
                      />
                    </div>
                  </div>
                </div>
                <ActionsDropdownButton
                  :actions="getAccountActionsList(row)"
                  class="--show-on-hover q-ml-md"
                />
              </div>
            </template>

            <template v-slot:status="row">
              <StatusBadge
                :status="row.status"
                icon-only
                :tooltip-inner-html="row.status_tooltip"
              />
            </template>

            <template v-slot:connected_inventory_sources="row">
              <div>
                <small v-if="row.connected_inventory_sources == 0">
                  <router-link
                    :to="{
                      name: 'InventorySourceCreator'
                    }"
                  >
                    Add Source(s)
                  </router-link>
                  <q-tooltip anchor="center right" self="center left">
                    Add inventory source(s) to this account. Sources connect
                    your
                    <br />products to the network, allowing activation of your
                    account.
                  </q-tooltip>
                </small>
                <a
                  v-else
                  @click="
                    () => {
                      handleManageAccountSources(row.id);
                    }
                  "
                >
                  {{ row.connected_inventory_sources }}
                  <q-tooltip anchor="center right" self="center left">
                    Manage Sources
                  </q-tooltip>
                </a>
              </div>
            </template>

            <template v-slot:products_active_total="row">
              <router-link
                :to="
                  '/advertiser/' + $store.state.productTypeConfigs.type + 's?advertiser_account_id=' +
                    row.id
                "
              >
                {{ numberFormatter(row.products_active_total) }}
                <q-tooltip anchor="center right" self="center left">
                  <span>View {{ $store.state.productTypeConfigs.typeFormatted }}s</span>
                </q-tooltip>
              </router-link>
            </template>

            <template v-slot:bid_method="row">
              <q-btn
                  flat
                  round
                  dense
                  icon="sym_r_edit_square"
                  :to="{
                  name: 'AdvertiserAccountEditor',
                  params: {
                    advertiserAccountId: row.id
                  },
                  query: {
                    bid_strategy_initial: 1
                  }
                }"
                  color="subtle"
                  size="9px"
                  class="q-mr-xs --show-on-hover"
              >
                <q-tooltip anchor="center left" self="center right">
                  Edit Bid Strategy
                </q-tooltip>
              </q-btn>
              {{
                row.bid_method == "auto" ? "Automated" : "Manual"
              }}
            </template>

            <template v-slot:daily_budget="row">
              <q-btn
                  flat
                  round
                  dense
                  icon="sym_r_edit_square"
                  :to="{
                  name: 'AdvertiserAccountEditor',
                  params: {
                    advertiserAccountId: row.id
                  },
                  query: {
                    daily_budget_initial: 1
                  }
                }"
                  color="subtle"
                  size="9px"
                  class="q-mr-xs --show-on-hover"
              >
                <q-tooltip anchor="center left" self="center right">
                  Edit Daily Budget
                </q-tooltip>
              </q-btn>
              {{
                row.daily_budget
                  ? numberFormatter(row.daily_budget, {
                      style: "currency",
                      currency: "USD"
                    })
                  : "-"
              }}
            </template>

            <template v-slot:current_day_performance>
              <div></div>
            </template>

            <template v-if="!loading" v-slot:custom-totals-row>
              <td>
                Totals for
                {{ numberFormatter(accountsTotals.accounts) }} {{ accountsTotals.accounts == 1 ? "Account" : "Accounts" }}
              </td>
              <td>-</td>
              <td class="text-right">
                {{
                  numberFormatter(accountsTotals.connected_inventory_sources)
                }}
              </td>
              <td class="text-right">
                {{ numberFormatter(accountsTotals.products_active_total) }}
              </td>
              <td class="text-right">-</td>
              <td class="text-right">
                {{
                  numberFormatter(accountsTotals.cpc, {
                    style: "currency",
                    currency: "USD"
                  })
                }}
              </td>
              <td class="text-right">
                {{ accountsTotals.daily_budget == 0 ? "-" : numberFormatter(accountsTotals.daily_budget, {
                style: "currency",
                currency: "USD",
                maximumFractionDigits: 0
              }) }}
              </td>
              <td class="text-right">
                {{
                  numberFormatter(accountsTotals.spend, {
                    style: "currency",
                    currency: "USD"
                  })
                }}
              </td>
            </template>
          </DataTable>

          <DataTable
            v-if="selectedView == 'sources'"
            table-key="inventorySourcesOverview"
            row-key="id"
            :columns="inventorySourcesColumns"
            :rows="inventorySources"
            :pagination="{
              sortBy: 'source',
              descending: false,
              page: 1,
              rowsPerPage: 10,
            }"
            sticky-column-table
            spinner-loader
            :loading="loading"
            :downloadable="true"
            :refreshable="true"
            :customizable="false"
            :filterable="true"
            :searchable="true"
            :disable-state="true"
            :custom-styles="{ 'border-top-left-radius': 0 }"
            :time-last-updated="inventorySourcesTimeLastUpdated"
            @refresh-rows="
              () => {
                populateAdvertiserAccounts();
              }
            "
            @filter-reset="
              () => {
                resetManageAccountSources();
              }
            "
          >
            <template v-slot:title>
              <q-btn
                round
                dense
                icon="sym_r_add"
                :to="{
                  name: 'InventorySourceCreator'
                }"
                color="primary"
              >
                <q-tooltip anchor="center right" self="center left">
                  Add New Source
                </q-tooltip>
              </q-btn>
            </template>

            <template v-slot:source="row">
              <div class="flex items-center justify-between no-wrap">
                <div>
                  <p>{{ row.name }}</p>
                  <div class="flex items-center">
                    <Label :text="row.id" />
                    <q-badge
                      color="gray-light"
                      text-color="gray-medium-dark"
                      :label="'Account ID ' + row.advertiser_account_id"
                      class="q-ml-sm"
                    />
                  </div>
                </div>
                <ActionsDropdownButton
                  :actions="getSourceActionsList(row)"
                  class="--show-on-hover q-ml-md"
                />
              </div>
            </template>

            <template v-slot:status="row">
              <StatusBadge
                :status="
                  row.active == 0
                    ? 'deactivated'
                    : row.paused == 1
                    ? 'paused'
                    : 'active'
                "
                icon-only
              />
              <q-icon
                v-if="row.import_status.length > 0"
                name="sym_r_sync"
                color="subtle"
                size="28px"
                class="q-ml-sm --spin-animation"
              >
                <q-tooltip anchor="center right" self="center left">
                  {{
                    row.active == 0
                      ? "Deactivating..."
                      : "Import Running..."
                  }}
                </q-tooltip>
              </q-icon>
            </template>

            <template v-slot:account="row">
              <span>{{ row.advertiser_account_name }}</span>
              <div class="flex items-center">
                <Label :text="row.advertiser_account_id" />
                <q-badge
                  v-if="row.business_id != $store.state.business.record.id"
                  color="gray-light"
                  text-color="gray-medium-dark"
                  :label="'Shared by ' + row.business_name"
                  class="q-ml-sm"
                />
              </div>
            </template>

            <template v-slot:source_type="row">
              <p>{{ row.source_type == "feed" ? "Hosted File" : "Manual Upload" }}</p>
              <Label
                v-if="row.source_type == 'feed'"
                :text="row.protocol + '://' + row.host + '/' + row.filename"
              />
            </template>

            <template v-slot:products_active_total="row">
              <router-link
                :to="
                  '/advertiser/' + $store.state.productTypeConfigs.type + 's?inventory_source_id=' + row.id
                "
              >
                {{ numberFormatter(row.products_active_total) }}
                <q-tooltip anchor="center right" self="center left">
                  <span>View {{ $store.state.productTypeConfigs.typeFormatted }}s</span>
                </q-tooltip>
              </router-link>
            </template>

            <template v-slot:time_last_imported="row">
              <q-btn
                  v-if="row.time_last_imported"
                  flat
                  round
                  dense
                  icon="sym_r_manage_search"
                  :to="{
                    name: 'InventorySourceImportHistory',
                    params: {
                      advertiserAccountId: row.advertiser_account_id,
                      inventorySourceId: row.id
                    }
                  }"
                  color="subtle"
                  size="12px"
                  class="--show-on-hover q-mr-xs"
              >
                <q-tooltip anchor="center left" self="center right">
                  View Import History
                </q-tooltip>
              </q-btn>
              {{
                row.time_last_imported
                    ? convertUtcToBusinessTimezone(
                        row.time_last_imported,
                        "lll"
                    )
                    : "-"
              }}
            </template>
          </DataTable>
        </template>
      </TabsContent>
    </template>
  </div>
</template>

<script>
import PageTitlebar from "@/components/UI/PageTitlebar";
import { Dialog } from "quasar";
import DataTable from "@/components/UI/DataTable";
import ConfirmationModal from "@/components/UI/ConfirmationModal";
import Label from "@/components/UI/Label";
import StatusBadge from "@/components/UI/StatusBadge.vue";
import TabsContent from "@/components/UI/TabsContent.vue";
import ActionsDropdownButton from "@/components/UI/ActionsDropdownButton.vue";
import * as cronParser from "cron-parser";
import AccountsMixin from "@/mixins/Advertiser/AccountsMixin";
import InventorySourcesMixin from "@/mixins/Advertiser/InventorySourcesMixin";

export default {
  name: "AdvertiserAccountsOverview",
  mixins: [AccountsMixin, InventorySourcesMixin],
  components: {
    ActionsDropdownButton,
    TabsContent,
    StatusBadge,
    Label,
    DataTable,
    PageTitlebar
  },
  data() {
    return {
      loading: true,
      selectedView: "accounts",
      accounts: [],
      accountsTotals: {},
      accountsTimeLastUpdated: "",
      accountsColumns: [
        {
          name: "active",
          label: "Active",
          field: row => (row.active == 1 ? "Active" : "Inactive"),
          type: "dimension",
          visible: false
        },
        {
          name: "id",
          label: "Account ID",
          sort: (a, b) => this.sortInteger(a, b),
          field: "id",
          type: "dimension",
          visible: false
        },
        {
          name: "name",
          label: "Account Name",
          field: "name",
          type: "dimension",
          visible: false
        },
        {
          name: "account",
          label: "Account",
          field: "id",
          sort: (a, b) => this.sortInteger(a, b),
          type: "dimension",
          hideField: true,
          filterable: false
        },
        {
          name: "status",
          label: "Delivery Status",
          field: row => row.status,
          format: value => this.capitalizeText(value),
          hideField: true,
          filterable: true,
          sortable: true,
          type: "dimension"
        },
        {
          name: "connected_inventory_sources",
          label: "Connected Sources",
          field: "connected_inventory_sources",
          hideField: true,
          sort: (a, b) => this.sortInteger(a, b),
          type: "metric",
          metricType: "number",
          align: "right"
        },
        {
          name: "products_active_total",
          label: "Active " + this.$store.state.productTypeConfigs.typeFormatted + "s",
          field: "products_active_total",
          hideField: true,
          sortable: true,
          sort: (a, b) => this.sortInteger(a, b),
          type: "metric",
          metricType: "number"
        },
        {
          name: "bid_method",
          label: "Bid Strategy",
          field: row => row.bid_method == "auto" ? "Automated" : "Manual",
          hideField: true,
          sortable: true,
          type: "dimension",
          align: "right"
        },
        {
          name: "cpc",
          label: "Current Day CPC",
          field: "cpc",
          format: value =>
              !value || value == "-"
                  ? "-"
                  : this.numberFormatter(value, {
                    style: "currency",
                    currency: "USD"
                  }),
          sortable: true,
          sort: (a, b) => this.sortMoney(a, b),
          type: "metric",
          metricType: "number",
          style: "width: 40px"
        },
        {
          name: "daily_budget",
          label: "Daily Spend Limit",
          field: "daily_budget",
          hideField: true,
          sortable: true,
          sort: (a, b) => this.sortInteger(a, b),
          type: "metric"
        },
        {
          name: "spend",
          label: "Current Day Spend",
          field: "spend",
          format: value =>
            !value || value == "-"
              ? "-"
              : this.numberFormatter(value, {
                  style: "currency",
                  currency: "USD"
                }),
          sortable: true,
          sort: (a, b) => this.sortMoney(a, b),
          type: "metric",
          metricType: "number"
        }
      ],
      inventorySources: [],
      inventorySourcesTimeLastUpdated: "",
      inventorySourcesColumns: [
        {
          name: "id",
          label: "Source ID",
          field: "id",
          sort: (a, b) => this.sortInteger(a, b),
          type: "dimension",
          visible: false
        },
        {
          name: "name",
          label: "Source Name",
          field: "name",
          type: "dimension",
          visible: false
        },
        {
          name: "source",
          label: "Inventory Source",
          field: "id",
          sort: (a, b) => this.sortInteger(a, b),
          type: "dimension",
          hideField: true,
          filterable: false,
          style: "width: 40px"
        },
        {
          name: "status",
          label: "Status",
          field: row =>
            row.active == 0
              ? "Deactivated"
              : row.paused == 1
              ? "Paused"
              : "Active",
          filterable: true,
          sortable: true,
          hideField: true,
          type: "dimension",
          style: "width: 40px",
        },
        {
          name: "advertiser_account_name",
          label: "Account Name",
          field: "advertiser_account_name",
          type: "dimension",
          visible: false
        },
        {
          name: "advertiser_account_id",
          label: "Account ID",
          field: "advertiser_account_id",
          type: "dimension",
          sort: (a, b) => this.sortInteger(a, b),
          visible: false,
          filterValues: {
            in: this.$route.query.advertiser_account_id
              ? [this.$route.query.advertiser_account_id]
              : [],
            notIn: [],
            gt: "",
            lt: ""
          }
        },
        {
          name: "source_type",
          label: "Source Type",
          field: row => (row.source_type == "feed" ? "Hosted File" : "Manual Upload"),
          sortable: true,
          type: "dimension",
          hideField: true
        },
        {
          name: "products_active_total",
          label: "Active " + this.$store.state.productTypeConfigs.type + "s",
          field: "products_active_total",
          hideField: true,
          sortable: true,
          sort: (a, b) => this.sortInteger(a, b),
          type: "metric",
          metricType: "number"
        },
        {
          name: "time_last_imported",
          label: "Last Import Completed",
          field: "time_last_imported",
          sortable: true,
          sort: (a, b) => this.sortDate(a, b),
          type: "metric",
          metricType: "date",
          hideField: true
        },
        {
          name: "daily_import_time",
          label: "Next Scheduled Import",
          field: row => {
            let cronSchedule = new cronParser.parseExpression(
              row.cron_schedule_expression
            );
            return row.active == 0 || row.paused == 1 || !row.cron_schedule_expression
              ? "-"
              : this.convertUtcToBusinessTimezone(
                  this.$dayjs(cronSchedule.next().toString()).format(
                    "YYYY-MM-DD HH:mm:ss"
                  ),
                  "lll"
                );
          },
          sortable: true,
          sort: (a, b) => this.sortInteger(a, b),
          type: "metric",
          metricType: "date",
        }
      ],
      manageSourcesSelectedAccountIds: []
    };
  },
  computed: {},
  watch: {
    selectedView(newVal) {
      if (newVal === "accounts") {
        this.resetManageAccountSources(true);
        this.populateAdvertiserAccounts();
      } else if (newVal === "sources") {
        this.populateInventorySources();
      }
    }
  },
  mounted() {
    this.checkAccountCreationSuccessModal();
    this.populateAdvertiserAccounts().then(() => {
      this.manageSourcesSelectedAccountIds = this.$route.query
        .advertiser_account_id
        ? [this.$route.query.advertiser_account_id]
        : [];
      if (
        this.manageSourcesSelectedAccountIds.length > 0 ||
        this.$route.query.to_sources == 1
      ) {
        this.selectedView = "sources";
        if (this.$route.query.to_sources == 1) {
          this.removeUrlParamsFromWindow(["to_sources"]);
        }
      }
    });
  },
  methods: {
    populateAdvertiserAccounts() {
      return new Promise(resolve => {
        this.loading = true;

        // TODO: Below into a fetchAdvertiserAccounts(includeCurrentDayReport = false) Accounts mixin?
        this.$store.dispatch("advertiserAccounts/GET_REQUEST", {
          params: {}
        }).then(accounts => {
          this.$store
            .dispatch("advertiserReporting/POST_REQUEST", {
              params: {
                report_payload: {
                  start_date: this.$dayjs()
                    .tz()
                    .format("YYYY-MM-DD"),
                  end_date: this.$dayjs()
                    .tz()
                    .format("YYYY-MM-DD"),
                  options: {
                    include_results_name_fields: true,
                    include_results_id_fields: true,
                    dimension_key_indexed_results: false,
                    format_results: false,
                    include_totals: true,
                    include_time_last_updated: true
                  },
                  dimensions: {
                    advertiser_account: []
                  },
                  metrics: {
                    spend: [],
                    clicks: [],
                    cpc: []
                  }
                }
              }
            })
            .then(report => {
              let reportRows = "rows" in report ? report.rows : [];
              reportRows.forEach(reportRow => {
                let accountsIndex = accounts.findIndex(
                  account => account.id == reportRow.advertiser_account_id
                );
                accounts[accountsIndex] = {
                  ...accounts[accountsIndex],
                  impressions: reportRow.impressions,
                  spend: reportRow.spend,
                  visitors: reportRow.visitors,
                  clicks: reportRow.clicks,
                  cpm: reportRow.cpm,
                  ctr: reportRow.ctr,
                  cpc: reportRow.cpc
                };
              });

              this.updateAccountsTotals(accounts);

              this.accountsTimeLastUpdated =
                "time_last_updated" in report.metadata
                  ? report.metadata.time_last_updated
                  : "";

              this.accounts = accounts;
              this.loading = false;
              resolve();
            });
        });
      });
    },
    getAccountActionsList(account) {
      let actionsList = [
        {
          action: () => this.handleManageAccountSources(account.id),
          label: "Manage Sources",
          icon: "sym_r_tune"
        },
        {
          action: () =>
            this.$router.push({
              name: "AdvertiserAccountEditor",
              params: {
                advertiserAccountId: account.id
              }
            }),
          label: "Edit Account",
          icon: "sym_r_edit"
        }
        /*{
          action: () => $router.push('/'),
          label: 'Share Account',
          icon: 'sym_r_share'
        },*/
      ];

      if (
        account.connected_inventory_sources > 0 &&
        (account.paused == 1 || account.active == 0)
      ) {
        actionsList.push({
          action: () =>
            this.promptResumeAccount(account).then(() =>
              this.populateAdvertiserAccounts()
            ),
          label: "Resume Delivery",
          icon: "sym_r_play_circle"
        });
      } else if (account.active_inventory_sources > 0) {
        actionsList.push({
          action: () =>
            this.promptStopAccount(account).then(() =>
              this.populateAdvertiserAccounts()
            ),
          label: "Stop Delivery",
          icon: "sym_r_stop_circle"
        });
      }

      actionsList.push({
        action: () =>
          this.promptDeleteAccount(account).then(() =>
            this.populateAdvertiserAccounts()
          ),
        label: "Delete Account",
        icon: "sym_r_delete",
        disabled:
          account.paused == 0 &&
          account.active == 1 &&
          account.status != "disabled",
        disabledTooltip:
          "You must stop delivery on the account before deleting."
      });

      return actionsList;
    },
    checkAccountCreationSuccessModal() {
      let newAdvertiserAccountStorage = sessionStorage.getItem(
        "jf-LastAdvertiserAccountCreatorSuccess"
      );
      if (!newAdvertiserAccountStorage) return;

      let newAdvertiserAccount = JSON.parse(newAdvertiserAccountStorage);
      sessionStorage.removeItem("jf-LastAdvertiserAccountCreatorSuccess");

      Dialog.create({
        component: ConfirmationModal,
        componentProps: {
          header: "You've successfully created your account.",
          subHeader:
            "<b class='q-mr-xs'>" +
            newAdvertiserAccount.name +
            "</b><small>" +
            newAdvertiserAccount.id +
            "</small>",
          message:
            "To activate this account and begin delivering ads, you will need to connect an inventory source. <span class='text-weight-medium'>Do you want to add an inventory source now?</span>",
          icon: "sym_r_check_circle",
          iconClass: "--icon-filled",
          buttonLabel: "Add Inventory Source",
          secondaryButtonLabel: "I'll do it later."
        }
      }).onOk(() => {
        this.$router.push({
          name: "InventorySourceCreator"
        });
      });
    },
    handleManageAccountSources(advertiserAccountId) {
      this.addUrlParamsToWindow([
        { name: "advertiser_account_id", value: advertiserAccountId }
      ]);
      this.manageSourcesSelectedAccountIds = [advertiserAccountId];
      this.inventorySourcesColumns[
        this.inventorySourcesColumns.findIndex(
          sourceCol => sourceCol.name == "advertiser_account_id"
        )
      ].filterValues = {
        in: this.manageSourcesSelectedAccountIds,
        notIn: [],
        gt: "",
        lt: ""
      };
      this.selectedView = "sources";
    },
    resetManageAccountSources(resetInventorySourceColumns = false) {
      this.removeUrlParamsFromWindow(["advertiser_account_id"]);
      this.manageSourcesSelectedAccountIds = [];
      if (resetInventorySourceColumns) {
        this.inventorySourcesColumns[
          this.inventorySourcesColumns.findIndex(
            sourceCol => sourceCol.name == "advertiser_account_id"
          )
        ].filterValues = {
          in: [],
          notIn: [],
          gt: "",
          lt: ""
        };
      }
    },
    updateAccountsTotals(datatableRows) {
      let newTotals = JSON.parse(JSON.stringify(this.accountsTotals));
      Object.keys(newTotals).map(key => {
        newTotals[key] = 0;
      });

      datatableRows.forEach(visibleAccount => {
        ++newTotals.accounts;
        newTotals.connected_inventory_sources += +visibleAccount.connected_inventory_sources;
        newTotals.active_inventory_sources += +visibleAccount.active_inventory_sources;
        newTotals.products_active_total += +visibleAccount.products_active_total;
        newTotals.spend += isNaN(+visibleAccount.spend)
            ? 0
            : +visibleAccount.spend;
        newTotals.daily_budget += isNaN(+visibleAccount.daily_budget)
            ? 0
            : +visibleAccount.daily_budget;
        newTotals.clicks += isNaN(+visibleAccount.clicks)
            ? 0
            : +visibleAccount.clicks;
        /* newTotals.impressions += isNaN(+visibleAccount.impressions)
            ? 0
            : +visibleAccount.impressions;
        newTotals.visitors += isNaN(+visibleAccount.visitors)
            ? 0
            : +visibleAccount.visitors;
        */
      });
      // newTotals.ctr = newTotals.impressions > 0 && newTotals.clicks > 0 ? (newTotals.clicks / newTotals.impressions) * 100 : 0;
      // newTotals.cpm = newTotals.impressions > 0 && newTotals.spend > 0 ? newTotals.spend / (newTotals.impressions / 1000) : 0;
      newTotals.cpc = newTotals.clicks > 0 && newTotals.spend > 0 ? newTotals.spend / newTotals.clicks : 0;

      this.accountsTotals = newTotals;
    },
    populateInventorySources() {
      this.loading = true;

      this.$store
        .dispatch("inventorySources/GET_REQUEST", {
          params: {}
        })
        .then(data => {
          setTimeout(() => {
            this.inventorySources = data;
            this.loading = false;

            this.checkInventorySourceCreationSuccessModal();
          }, 100);
        });
    },
    checkInventorySourceCreationSuccessModal() {
      let newInventorySourceStorage = sessionStorage.getItem(
        "jf-LastInventorySourceCreatorSuccess"
      );
      if (!newInventorySourceStorage) return;

      let newInventorySource = JSON.parse(newInventorySourceStorage);
      sessionStorage.removeItem("jf-LastInventorySourceCreatorSuccess");

      Dialog.create({
        component: ConfirmationModal,
        componentProps: {
          header: "You've successfully added your inventory source.",
          subHeader:
            "<b class='q-mr-xs'>" +
            newInventorySource.name +
            "</b><small>" +
            newInventorySource.id +
            "</small>",
          message:
            "You're all set! Your inventory source has been connected to your account <span class='text-weight-medium'>" +
            this.advertiserAccount.name +
            "</span>. <br/><br/>We've started importing your products. Once complete, this account will begin delivering ads for your products.",
          icon: "sym_r_check_circle",
          iconClass: "--icon-filled",
          buttonLabel: "Done",
          hideSecondaryButton: true
        }
      }).onOk(dialog => {
        dialog.hide();
      });
    },
    getSourceActionsList(source) {
      let actionsList = [
        {
          action: () =>
            this.$router.push({
              name: "InventorySourceEditor",
              params: {
                advertiserAccountId: source.advertiser_account_id,
                inventorySourceId: source.id
              }
            }),
          label: "Edit Source",
          icon: "sym_r_edit"
        },
        {
          action: () =>
            this.$router.push({
              name: "InventorySourceImportHistory",
              params: {
                advertiserAccountId: source.advertiser_account_id,
                inventorySourceId: source.id
              }
            }),
          label: "View Import History",
          icon: "sym_r_manage_search"
        }
      ];

      if (source.active == 1) {

        if (source.source_type === "feed") {
          actionsList.push({
            action: () =>
                this.promptRunSourceManualImport(source).then(() =>
                    this.populateInventorySources()
                ),
            label: "Run Import",
            icon: "sym_r_sync",
            disabled: source.import_status.length > 0,
            disabledTooltip: "Disabled while import is running."
          });

          if (source.paused == 0) {
            actionsList.push({
              action: () =>
                  this.promptPauseSource(source).then(() =>
                      this.populateInventorySources()
                  ),
              label: "Pause Imports",
              icon: "sym_r_pause",
              disabled: source.import_status.length > 0,
              disabledTooltip: "Disabled while import is running."
            });
          } else {
            actionsList.push({
              action: () =>
                  this.promptResumeSource(source).then(() =>
                      this.populateInventorySources()
                  ),
              label: "Resume Imports",
              icon: "sym_r_play_arrow"
            });
          }
        }
        else {
          actionsList.push({
            action: () =>
              this.$router.push({
                name: "InventorySourceEditor",
                params: {
                  advertiserAccountId: source.advertiser_account_id,
                  inventorySourceId: source.id
                },
                query: {
                  manual_upload_initial: 1
                }
              }),
            label: "Upload New File",
            icon: "sym_r_upload_file",
            disabled: source.import_status.length > 0,
            disabledTooltip: "Disabled while import is running."
          });
        }

        actionsList.push({
          action: () =>
            this.promptDeactivateSource(source).then(() =>
              this.populateInventorySources()
            ),
          label: "Deactivate Source",
          icon: "sym_r_stop_circle",
          disabled: source.import_status.length > 0,
          disabledTooltip: "Disabled while import is running."
        });
      } else {
        actionsList.push({
          action: () =>
            this.promptActivateSource(source).then(() =>
              this.populateInventorySources()
            ),
          label: "Activate Source",
          icon: "sym_r_play_circle"
        });
        actionsList.push({
          action: () =>
            this.promptDeleteSource(source).then(() =>
              this.populateInventorySources()
            ),
          label: "Delete Source",
          icon: "sym_r_delete"
        });
      }

      return actionsList;
    }
  }
};
</script>

<style scoped lang="scss"></style>
